<template>
    <!-- Momento 2 es reclamo -->
    <section>
        <div class="row mx-0 justify-content-end py-2">
            <div v-if="detalle.estado == 31" class="bg-general text-white px-3 py-1 br-12 cr-pointer" @click="cerrarSoporte">
                Finalizar reclamos
            </div>
        </div>
        <div class="custom-scroll overflow-auto" style="height:calc(100vh - 287px)">
            <chat
            :tipo="5"
            :user="id_pedido"
            :momento="2"
            :finalizado="detalle.estado == 32"
            :fecha-finalizado="detalle.fecha_ult_reclamo"
            @actualizar-lectura="actualizarLectura"
            />
        </div>
        <modalConfirmar ref="modalCerrarSoporte" titulo="Cerrar soporte" icon="checked" mensaje="¿Desea cerrar este caso?" @guardar="finalizarReclamo" />
    </section>
</template>

<script>
import {mapGetters} from 'vuex'
import ReclamosLeecheros from '~/services/reclamos/reclamosLeecheros'

export default {
    computed:{
        ...mapGetters({
            id_pedido: 'pedidos/pedidos_historial_tendero/id_pedido',
            detalle: 'pedidos/pedidos_historial_tendero/pedido',
        }),

    },
    methods: {

        async actualizarLectura(){
            console.log('-----  ----actualizarLecturaa-   -------', );
            await this.actualizarUltimaLectura()
        },
        async actualizarUltimaLectura(){
            try {
                const enviar ={
                    id_pedido: this.id_pedido
                }
                const {data} = await ReclamosLeecheros.actualizarUltimaLectura(enviar)

            } catch (e){
                this.error_catch(e)
            }
        },
        cerrarSoporte(){
            this.$refs.modalCerrarSoporte.toggle()
        },
        async finalizarReclamo(){
            try {

                const {data} = await ReclamosLeecheros.finalizarReclamo(this.detalle.id)
                this.notificacion('Mensaje', 'Reclamo finalizado', 'success')
                this.$refs.modalCerrarSoporte.toggle()
                this.$emit('actualizar')
                this.$store.commit('pedidos/pedidos_historial_tendero/set_id_pedido', null)
                this.$store.dispatch('pedidos/pedidos_historial_tendero/set_data')

            } catch (e){
                this.error_catch(e)
            }
        },
    }
}
</script>

<style lang="css" scoped>
</style>
